/*** Upload Download Icons ***/
.uploadButtonIcon {
	height: 80px;
	width: 80px;
	display: inline-block;
	background-size: contain;
	background-position: center;
	background-image: url("/assets/images/transparent-background-dragdropupload.png");
}

.downloadButtonIcon {
	height: 70px;
	width: 70px;
	display: inline-block;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	background-image: url("/assets/images/download.png");
}

.pointer {
	cursor: pointer;
}

/*** General Styles ***/
.ListTitle {
	background-color: #f4f7ff;
	font-size: 1em !important;
	font-weight: bold;
	color: #000 !important;
	height: auto !important;
	padding: 10px 0px !important;
	border-bottom: 1px solid #e7e9ef;
}

.mat-input-element {
	background-color: #fff;
}

mat-card mat-list,
.dialog-container mat-list {
	max-height: calc(100vh - 350px);
	background-color: transparent !important;
}

/* Dialog Container */
.mat-dialog-container {
	background-color: #fff;
	padding: 42px 53px 53px 53px !important;
	border-radius: 10px !important;
	box-shadow: none !important;
	box-sizing: border-box !important;
	height: auto !important;
	max-height: calc(100vh - 40px) !important;
	overflow: auto !important;

	p {
		margin-bottom: 15px;
	}
}

#editionSelect .mat-select-value-text {
	opacity: 0 !important;
}

.mat-dialog-content {
	display: block;
	min-height: 120px;
	margin: 0px !important;
	padding: 0px !important;
	overflow: auto;
	box-sizing: border-box;
	-webkit-overflow-scrolling: touch;
	max-height: calc(100vh - 40px) !important;
}

/* Button Styles*/
// added by PS
.menuButton {
	display: flex !important;
	align-items: center;
}

.mat-raised-button {
	border-radius: 4px !important;
}

/**** QUOTAS ****/
.quotaContainerDialog {
	width: 100%;
	max-width: 815px;
}

.mat-sidenav {
	* {
		color: $tGrey2;
	}

	.mat-list-text {
		font-weight: bold;
		transition: all 350ms;
	}

	.mat-list-item-content {
		padding-right: 20px;
		transition: all 350ms;

		* {
			transition: all 350ms;
		}

		&:hover {
			color: $purple !important;

			* {
				color: $purple !important;
			}
		}
	}

	.mat-list-base .mat-list-item .mat-list-text,
	.mat-list-base .mat-list-option .mat-list-text {
		padding-right: 20px !important;
	}
}

textarea.mat-input-element {
	margin: 0px !important;
}

.mat-dialog-actions {
	margin-bottom: 0px !important;
	justify-content: flex-end;
}

.quota {
	padding: 20px;
	box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
	border-radius: 8px;
	overflow: hidden;
	position: relative;
	background-color: #ffffff !important;
}

/*** General Styles ***/
#MainAppContainer {
	padding-bottom: 35px;
	height: 100%;
	//padding-top: 20px;
	overflow: hidden;
}

.note {
	position: relative;
	padding: 20px;
	background: #e8edf452;
	border-radius: 10px;
	color: #8687a7;

	&.note-blue {
		background: #0a97ff1c;
		color: #6074e2;
		border: 1px solid #6074e245;
	}
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
	background-color: rgb(222, 222, 222) !important;
}

.ExternalUserText {
	color: #8591ab;
	text-transform: uppercase;
	letter-spacing: 3px;
	font-size: 0.8em;
	font-weight: bold;
	font-style: italic;
	margin-top: -10px;
}

app-logout,
app-login-selection,
app-usage .usageContainer,
app-products,
#level2-content {
	background: $bgGrey1;
}

#MainAppContainer,
app-logout,
app-login-selection {
	background-color: $bgGrey1;
}

/*** New Theme ***/

.mat-tab-link-container {
	padding-left: 20px;
	padding-right: 20px;
}

nav.mat-tab-header {
	border-bottom: none !important;
	padding-top: 20px;
}

#level2-navigation-no-title {
	nav.mat-tab-header {
		padding-top: 0px;
	}
}

#level2-navigation {
	width: 100%;
	display: block !important;

	nav.mat-tab-header {
		padding-top: 0px;
	}

	.mat-tab-link {
		background: transparent;
		// border-radius: 5px;
		// margin-right: 10px;

		font-weight: bold !important;

		i {
			font-size: 18px;
			color: #8687a7 !important;
			margin-right: 10px;
		}
	}

	.mat-tab-link.mat-tab-label-active {
		color: #6074e2 !important;
		border-bottom: 3px solid #6074e2;

		i {
			color: #6074e2 !important;
			margin-right: 10px;
		}
	}
}

.top-level-tabs > .mat-tab-body-wrapper {
	background-color: transparent !important;
	box-shadow: none !important;
}

.top-level-tabs > .mat-tab-header {
	.mat-tab-labels:first-of-type {
		padding-bottom: 30px;
	}

	.mat-tab-labels:first-of-type .mat-tab-label {
		background: transparent;
		// border-radius: 5px;
		// margin-right: 10px;

		span {
			font-weight: bold !important;
		}

		i {
			font-size: 18px;
			color: #8687a7 !important;
			margin-right: 10px;
		}
	}

	.mat-tab-labels:first-of-type .mat-tab-label.mat-tab-label-active {
		span:not(.mat-badge-content) {
			color: #6074e2 !important;
		}

		border-bottom: 3px solid #6074e2;

		i {
			color: #6074e2 !important;
			margin-right: 10px;
		}
	}
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
	display: none !important;
}

.mat-form-field-label-wrapper,
.mat-tab-label,
.mat-tab-link {
	font-weight: 600 !important;
}

mat-expansion-panel:not([class*="mat-expanded"]).flush {
	background-color: #fff !important;
}

.list-container {
	background-color: transparent !important;
}

.mat-icon {
	color: #8587a6;
}

app-extract-index-list-item {
	.mat-icon {
		color: $tGrey2;
	}
}

app-fab-icon-busy-button .mat-icon {
	max-width: 24px;
}

.mat-list-item {
	float: left;
	width: 100%;
	border-radius: 4px;
	overflow: hidden;
	margin: 5px 0px;
	//background-color: #f3f6f9;
	background-color: #f8fafb;
	border: 2px solid transparent;
	transition: all 350ms;

	app-export-version-ball {
		.iconSize56 {
			font-size: 16px;
			width: 16px !important;
			height: 16px !important;
			line-height: 16px;
		}

		.iconSize40 {
			font-size: 12px !important;
			width: 12px !important;
			height: 12px !important;
			line-height: 12px;
		}
	}
}

.mat-list-item.selected-item {
	color: $tGrey1 !important;
	background-color: #e8edf4 !important;
	overflow: hidden;
	//padding-left: 5px;
	border: none;

	// &:before {
	//   z-index: 3;
	//   content: '';
	//   position: absolute;
	//   top: 0px;
	//   left: 0px;
	//   background: #6074e2;
	//   width: 3px;
	//   height: 100%;
	//   display: block;
	// }

	.mat-line,
	span:not(.mat-badge-content) {
		font-weight: 500 !important;
		color: #6074e2 !important;
	}

	.text-subtle {
		color: $tGrey2 !important;
	}
}

.SideList {
	z-index: 99 !important;
	overflow: auto;
	max-height: 100%;

	.mat-list-item {
		box-shadow: none !important;
		border: none;
	}

	.list-container-below-header {
		.mat-line {
			font-size: 13px !important;
			color: #8687a7;
			//color: #363f67;
			font-weight: 600 !important;
		}
	}

	.mat-flat-button.mat-accent,
	.mat-raised-button.mat-accent,
	.mat-mini-fab.mat-accent {
		background-color: transparent !important;
	}
}

.mat-chip.mat-standard-chip::after {
	background: transparent !important;
}

.list-title-bar {
	background-color: transparent !important;
	font-weight: normal !important;
	font-size: 1.2em !important;
	z-index: 10;
	width: 100% !important;
}

app-client-log,
app-list-search-header {
	.mat-icon {
		color: $tGrey1;
	}

	.mat-flat-button.mat-accent,
	.mat-raised-button.mat-accent,
	.mat-fab.mat-accent,
	.mat-mini-fab.mat-accent {
		background-color: transparent !important;
		box-shadow: none !important;
	}

	app-list-add-input {
		overflow: visible;
		position: absolute;
		top: 70px;
		right: 0px;
		z-index: 99;
		transform: translateY(-100%);
	}
}

#level2-content {
	padding-left: 20px;
}

.mat-tab-header-pagination {
	box-shadow: none !important;
}

.mat-dialog-title,
.titleUnderline {
	padding-bottom: 2px !important;
	margin-bottom: 15px !important;
}

.mat-dialog-title,
.card-title {
	font-weight: normal !important;
	font-size: 24px !important;
	line-height: 1.5em;
	min-height: 36px;
	background-color: transparent !important;
	color: $tGrey1 !important;
}

// .selectedTitle {
//   color: #4078a0 !important;
// }

.card-sub-title {
	font-weight: 600 !important;
	width: 100%;
	//font-size: 15px !important;
	font-size: 17px !important;
	color: $tGrey1 !important;
	// color: #495057 !important;
	padding-bottom: 5px !important;
	margin-bottom: 15px !important;
	float: left;
}

.quota .card-sub-title {
	padding-bottom: 0px !important;
	margin-bottom: 0px !important;
	border-bottom: none;
}

.text-subsection-title {
	font-weight: 500 !important;
	width: 100%;
	font-size: 27px !important;
	color: $tGrey1 !important;
	letter-spacing: -1px;
}

.primary-title-bar {
	color: $tGrey1 !important;
	font-weight: 500 !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
	background-color: $blue !important;
}

// .mat-expansion-panel-header {
//   box-shadow: none !important;
//   color: #000 !important;
//   background: #e8edf43b;
//   font-weight: 500 !important;
//   font-size: 1em !important;
//   padding: 10px 20px !important;
//   height: auto !important;
// }

.mat-expansion-panel-header {
	box-shadow: none !important;
	color: #fff !important;
	background: #6074e2 !important;
	font-weight: 500 !important;
	font-size: 1em !important;
	padding: 10px 20px !important;
	height: auto !important;
}

.mat-expansion-panel-header-title {
	color: #fff !important;
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
	color: #fff !important;
}

.mat-expansion-panel {
	box-shadow: none !important;
	border-radius: 5px !important;
	background-color: #fff !important;
}

.cardScroll {
	overflow-x: hidden;
	overflow-y: auto;
	padding: 10px;
	width: 100%;
}

mat-tab-group .mat-tab-header {
	background-color: transparent !important;
	box-shadow: none !important;
}

.mat-tab-label-active {
	color: #363f67;
	box-shadow: none !important;
	background: #fff;

	* {
		color: #363f67;
	}

	.mat-badge-content {
		background: #6074e2;
	}
}

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label,
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
	background-color: #6074e2 !important;
}

.mat-slide-toggle.mat-checked .mat-ripple-element,
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
	.mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
	.mat-ripple-element {
	background-color: #6074e2 !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
	background-color: #6074e2ad !important;
}

.mat-menu-panel {
	border-radius: 4px !important;
	box-shadow: 0px 5px 40px 0px #435d7b66 !important;
}

.mat-divider {
	border-top-width: 3px !important;
	border-top-color: rgb(227, 235, 240) !important;
}

legend {
	font-weight: 500;
	font-size: 12px;
	background-color: #fff;
	padding: 0px 5px;
	color: #373f67;
}

.card {
	box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
	border-radius: 8px;
	//border-radius: 10px;
	overflow: hidden;
	position: relative;
	background-color: #ffffff !important;

	.mat-tab-label-container {
		padding-left: 10px;
	}

	.mat-tab-link.mat-tab-label-active {
		background-color: transparent;
	}

	* {
		box-sizing: border-box;
	}

	.searchForm + button {
		background-color: transparent !important;
	}

	.list-title-bar {
		width: 100% !important;
		margin-left: 0px !important;
	}

	fieldset {
		border-radius: 4px !important;
		border: 3px solid $bgGrey1;
		padding: 10px;
	}

	.SideList {
		padding: 0px !important;
		z-index: 99 !important;
	}

	.mat-ink-bar {
		display: block !important;
	}
}

.card-header {
	padding: 10px 20px;
	font-weight: 600;
	border-bottom: 1px solid rgba(0, 0, 0, 0.03);
	color: #363f67 !important;
	font-size: 15px;
}

.card-dark {
	background: #dbe2ea;
	box-shadow: inset 0px 0px 30px #ccd8e4;
	padding: 20px;
	border-radius: 8px;
}

.card-blue {
	background: #6074e2;
	border-bottom: 1px solid rgba(0, 0, 0, 0.03);
	padding: 20px;
	border-radius: 4px;
	color: #fff;

	* {
		color: #fff;
	}
}

.standard-card {
	border-radius: 15px;
	padding: 30px 30px 30px 30px;
	background-color: #fff;
	box-shadow: 0px 9px 40px 0px #edf2f7;
}

.mat-list-text {
	padding-left: 10px !important;
	padding-right: 10px !important;
}

.mat-ink-bar {
	display: none;
}

// New Tabs
.callout-above {
	margin: 4px 6px -19px 4px !important;
}

input.searchInput {
	height: auto;
	float: right;
	font-size: 13px;
	width: 100%;
	padding: 4px !important;
	border: none !important;
	padding-left: 4px !important;
	padding-right: 4px !important;
	border-radius: 4px !important;
	color: $tGrey2 !important;
	background-color: transparent;

	&::placeholder {
		color: #8687a78c !important;
	}
}

.banner {
	background-color: #ffffff !important;
	border-bottom: 2px solid #e5eaf2;
	margin-bottom: 40px;

	* {
		color: #363f67 !important;
		font-weight: normal !important;
	}

	.mat-card-title {
		font-size: 28px;
	}

	font-weight: 500 !important;
	font-size: 30px !important;
	letter-spacing: -1px !important;

	.mat-icon {
		width: 80px !important;
		font-size: 80px !important;
		height: 80px !important;
		color: #dbe0e8 !important;
	}
}

.mat-raised-button.mat-button-base.mat-primary,
.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary,
app-extract-data-in-out-tab .mat-raised-button.mat-primary,
.loginForm .mat-raised-button.mat-primary,
.buttonStyle .mat-raised-button.mat-primary,
.container .mat-raised-button.mat-primary,
.mat-dialog-actions .mat-raised-button.mat-primary {
	//background-color: #b289bf;
	background: #6074e2;
	font-size: 12px !important;
	padding: 6px 13px;
	min-height: 34px;
	font-weight: normal;
	line-height: 1.6;
	transition: all 200ms;
	box-shadow: none;
	position: relative;

	// &:before {
	//   content: "";
	//   display: block;
	//   position: absolute;
	//   top: 0px;
	//   width: 100%;
	//   background-color: #fff;
	//   height: 100%;
	//   opacity: 0.07;
	//   transform: skewX(45deg) translateX(54%);
	// }

	&:hover {
		background: #5e6fc5;
	}
}

.mat-raised-button.mat-button-disabled {
	background-color: rgba(0, 0, 0, 0.12) !important;
}

.flex-container.forceP20 {
	padding: 20px !important;
}

hr {
	height: 3px !important;
	color: $bgGrey1 !important;
	background-color: $bgGrey1 !important;
}

.mat-nav-list .mat-chip-list-wrapper {
	align-items: center;
}

mat-dialog-container,
.library-level2-content {
	height: calc(100vh - 310px) !important;
	background-color: #ffffff !important;

	.card {
		box-shadow: none !important;
	}

	.SideList {
		padding: 0px !important;

		.list-container-below-header {
			padding: 0px !important;
		}
	}
}

p {
	line-height: 2em;
	//font-size: 14px;
	font-size: 13px;
	//color: #3c4257;
	color: #8687a7;
}

#level2-content #level2-content {
	background-color: transparent !important;
	padding: 0px !important;
	height: auto !important;
}

textarea.mat-input-element.cdk-textarea-autosize {
	height: auto !important;
}

.swagger-ui .scheme-container {
	box-shadow: none !important;
}

textarea {
	border: 3px solid $bgGrey1;
	padding: 10px;
}

button#send {
	background: #6074e2;
	clear: both;
	display: block;
	margin-top: 21px;
}

label,
.mat-card-subtitle {
	font-weight: normal;
	color: #8687a7;
	font-size: 0.8em;
}

.mat-checkbox {
	overflow: hidden;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
	border: none !important;
}

.mat-drawer-container {
	background-color: #ffffff !important;
}

.mat-drawer:not(.mat-drawer-side) {
	box-shadow: none !important;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary
	.mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary
	.mat-ripple-element,
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
	background-color: #6074e2 !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
	.mat-radio-ripple
	.mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
	background-color: #6074e2 !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
	border-color: #6074e2 !important;
}

.mat-progress-spinner.mat-accent circle,
.mat-spinner.mat-accent circle {
	stroke: #6074e2 !important;
}

.mat-datepicker-content {
	box-shadow: 0px 0px 20px 0px #dbe3ea !important;
}

mat-header-cell:last-of-type {
	padding-right: 0px !important;
}

.flex-container.centerColumn {
	padding-right: 20px !important;
	padding-left: 20px !important;
}

.errorMessage {
	color: #f00d60;
	padding: 10px 20px;
	margin: 10px 0px;
	border-radius: 10px;
	display: inline-block;
	vertical-align: middle;
	background-color: #f00d601f;

	* {
		vertical-align: middle;
		color: #f00d60;
		display: inline-block;
	}
}

.successMessage {
	color: #03c185;
	padding: 10px 20px;
	margin: 10px 0px;
	border-radius: 10px;
	display: inline-block;
	vertical-align: middle;
	background-color: #03c18530;

	* {
		vertical-align: middle;
		color: #03c185;
		display: inline-block;
	}
}

.mat-icon.mat-accent {
	color: #b28abf !important;
}

app-exporter-mapping-node .mat-chip.mat-standard-chip,
app-extract-assets-tab .mat-chip.mat-standard-chip,
app-property-index-list-item .mat-chip.mat-standard-chip {
	background-color: #fff;
	border: 1px solid #e8edf3;
	color: rgb(116, 127, 144) !important;
	height: auto !important;
}

app-percentage-circle + span,
app-percentage-circle {
	font-weight: 500 !important;
	color: $tGrey2 !important;

	* {
		font-weight: 500 !important;
		color: $tGrey2 !important;
	}
}

.mat-form-field {
	.mat-input-element {
		color: #383f67 !important;
	}

	.mat-form-field-label {
		// color: #6b6c77 !important;
		color: #a7acc1 !important;
		font-weight: 500;
	}

	.mat-form-field-underline {
		background-color: $tGrey2 !important;
	}

	.mat-form-field-ripple {
		background-color: $tGrey2 !important;
	}

	.mat-form-field-required-marker {
		color: $tGrey2 !important;
	}
}

.ClientMenu,
.loginMenu {
	transition: all 150ms;
	width: 100%;
	opacity: 0;
	padding: 28px 14px 20px 14px;
	display: flex;
	z-index: 9999;
	min-width: 330px;
	overflow: hidden;
	white-space: nowrap;
}

.ClientMenu {
	bottom: auto;
	font-weight: normal;
	left: auto;
	opacity: 1;
	padding: 11px;
	border-radius: 5px;
	box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
	z-index: 9;
	width: auto !important;
	height: 32px;
	border: none !important;
	min-width: 0px !important;
	color: #363f67 !important;
	background: #6074e2;
	position: relative;
	transition: all 200ms;

	&:before {
		content: "";
		display: block;
		position: absolute;
		top: 0px;
		width: 100%;
		background-color: #fff;
		height: 100%;
		opacity: 0.07;
		transform: skewX(45deg) translateX(54%);
	}

	&:hover {
		background: #5e6fc5 !important;
	}

	* {
		color: #fff !important;
	}

	span {
		font-size: 0.8em;
	}
}

.loginMenu {
	background-color: #2a3041;
	max-width: 74px;
	right: auto;
	left: 0px;
	padding: 28px 14px 20px 22px !important;
	opacity: 1;
}

.mainLogo {
	// position: absolute;
	// left: 50%;
	// top: 16px;
	// height: 45px;
	// transform: translateX(-50%);
	// transition: all 150ms;
	position: absolute;
	left: 50%;
	top: 21px;
	height: 50px;
	transform: translateX(-50%);
	transition: all 150ms;
}

.secondaryLogo {
	// Hide for 500px
	@media (max-width: 500px) {
		display: none;
	}
}

#level3-item-view {
	width: 70%;
}

#level3-item-selection {
	width: 30%;
}

// Side Navigation
.SideBarToggle {
	display: none !important;
	-webkit-appearance: none;
	height: 20px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	background-image: url(/assets/images/toggle.png);
	width: 20px;
	margin-right: 10px;
	margin-bottom: -3px;
	cursor: pointer;
	transition: all 350ms;
	transform: rotate(0deg);
	transform-origin: 50% 50%;
}

.SideBarToggle:checked {
	transform: rotate(-180deg) !important;
}

.usageContainer {
	border-top: 1px solid #cfdae0;
	border-bottom: 1px solid #cfdae0;
	border: none !important;
}

.goog-te-menu-frame {
	z-index: 10000002;
	position: fixed;
	border: none;
	border-radius: 4px;
	background-color: #fff;
	box-shadow: none !important;
}

.app-chaining-properties {
	z-index: 1;
}

app-list-dialog .SideBarToggle {
	display: none !important;
}

.stageCard {
	float: left;
	clear: both;
	width: 100%;
	padding: 10px 10px !important;
	box-shadow: none !important;
	background-color: #e4ecf1;
}

.mat-card-actions,
.mat-card-content,
.mat-card-subtitle {
	display: block;
	margin-bottom: 16px;
	float: left;
	width: 100%;
}

.mat-card-content {
	* {
		color: $tGrey2;
	}
}

.mat-cell,
.mat-footer-cell {
	color: $tGrey2;
}

app-exporter-filters-tab .mat-tab-body-wrapper {
	height: 100%;
	border-radius: 0px 5px 5px 5px;
	min-height: 450px;
}

.tableContainer {
	display: block;
	overflow: auto;
	border: 1px solid #e8edf4;
	border-radius: 5px;
}

.mat-header-row {
	position: sticky;
	top: 0px;
	background-color: #fff !important;
	z-index: 1;
}

// Navigation Start ---------------------------------------------------------------------
#TopBar {
	padding: 25px 20px 43px 117px;

	.mat-icon {
		color: #8792a2;
	}
}

#Navigation {
	position: fixed;
	height: 100%;
	width: 100%;
	max-width: 330px;
	background-color: #2a3041;
	transition: all 150ms;
	z-index: 9999;
	top: 0px;
	left: 0px;
	padding: 90px 0px 0px 0px;
}

#MenuIcon {
	position: fixed;
	top: 20px;
	left: 15px;
	z-index: 99999;
	cursor: pointer;
	transition: all 150ms;
	padding: 10px;
	font-size: 25px;
	color: #fff;

	&:hover {
		span {
			background-color: #c7cfdc;
		}

		p {
			color: #fff;
		}

		.line-2 {
			width: 25px;
		}

		.line-1,
		.line-3 {
			width: 25px;
		}
	}
}

#MainMenu {
	transition: all 150ms;
	padding: 0px 0px;
	overflow: hidden;
	overflow-y: auto;
	width: 100%;
	max-width: 330px;
	display: flex;
	max-height: calc(100vh - 302px);

	* {
		padding: 0px;
		margin: 0px;
		list-style: none;
		float: left;
		width: 100%;
		position: relative;
		clear: both;
		//font-size: 1em;
		font-size: 1.02em;
	}

	ul ul ul {
		background-color: #334158;
		overflow: hidden;
		opacity: 0;
		transition: all 500ms;
		max-height: 0vh;
	}

	li {
		mat-icon,
		i {
			font-size: 1.3em;
			margin-left: 3px;
			margin-right: 10px;
		}

		span,
		i,
		mat-icon {
			display: inline-block;
			vertical-align: middle;
			width: auto;
			clear: none;
		}
	}

	a {
		border-radius: 0px;
		white-space: nowrap;
	}

	.activeLink {
		font-weight: 500 !important;

		color: #fff !important;
	}

	.LevelOne {
		.LevelOneItem {
			.LevelOneItemLink {
				padding: 10px 20px;
				margin-top: 20px;

				mat-icon,
				i {
					color: #fff;
				}

				span {
					color: #fff;
				}
			}
		}
	}

	.MenuToggle {
		float: none;
		position: absolute;
		right: 20px;
		top: 14px;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		height: 20px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: 15px;
		background-image: url(/assets/images/toggle.png);
		width: 20px;
		cursor: pointer;
		transition: all 350ms;
		transform: rotate(0deg);
		transform-origin: 50% 50%;

		&:hover {
			background-image: url(/assets/images/toggle2.png);
		}
	}

	.MenuToggle:checked {
		transform: rotate(180deg);
		background-image: url(/assets/images/toggle2.png);
	}

	.MenuToggle:checked + app-sidenav-route ul {
		opacity: 1;
		max-height: 100vh;
	}

	.ActiveLevelTwo .LevelTwoItemLink {
		background-color: #30384e;

		&:before {
			content: "";
			position: absolute;
			display: block;
			width: 2px;
			left: 0px;
			top: 0px;
			height: 100%;
			box-shadow: 0px 0px 40px 0px #6074e2;
			background-color: #40c2cc !important;
		}

		span {
			color: #fff !important;
			//font-weight: bold;
			font-size: 0.8em;
		}

		mat-icon,
		i {
			color: #fff !important;
		}
	}

	.LevelThreeItemLink.activeLink {
		background-color: #3c4661;
		color: #fff !important;

		&:before {
			content: "";
			position: absolute;
			display: block;
			box-shadow: 0px 0px 40px 0px #b28abf;
			background-color: #b28abf !important;
			width: 2px;
			left: 0px;
			top: 0px;
			height: 100%;
		}
	}

	.LevelTwo {
		.LevelTwoItem {
			.LevelTwoItemLink {
				padding: 10px 20px 10px 22px;
				color: #79829c;
				transition: all 350ms;

				&:hover {
					color: #fff;
					background-color: #30384e;
				}

				mat-icon {
					color: #c7cfdc;
				}

				span {
					padding-left: 10px;
					font-size: 0.8em;
				}
			}
		}
	}

	.LevelThree {
		.LevelThreeItem {
			.LevelThreeItemLink {
				padding: 11px 20px 14px 72px !important;
				color: #c7cfdc;
				font-weight: normal !important;
				font-size: 0.8em;
				transition: all 350ms;

				mat-icon,
				i {
					display: none !important;
				}

				&:hover {
					color: #fff;
					background-color: #30384e;
				}
			}
		}
	}

	a:hover + input {
		background-image: url(/assets/images/toggle2.png);
	}
}

.inactiveNav#Navigation {
	max-width: 74px;

	.loginMenu {
		min-width: 0px;

		span {
			opacity: 0 !important;
		}
	}

	.navLogoContainer {
		max-width: 48px;
	}

	.menuTitle {
		max-height: 0px;
		opacity: 0;
		padding: 0px 20px;
		margin: 0px;
	}

	.toolLabel {
		opacity: 0 !important;
	}

	.MenuToggle {
		display: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}

	.MenuToggle:checked + app-sidenav-route ul {
		opacity: 0;
		display: none;
		max-height: none;
	}

	ul ul ul {
		background-color: #35445f;
	}

	#MainMenu {
		padding: 0px 0px !important;
		overflow: visible !important;

		ul ul ul {
			background-color: transparent !important;
		}

		.LevelThree {
			mat-icon,
			i {
				font-size: 1.3em !important;
			}
		}

		.LevelTwoItemLink {
			transition: all 0ms;
			border-radius: 4px;
		}

		.LevelTwoItem.hasChildren {
			border-radius: 0px;

			.LevelTwoItemLink {
				border-radius: 4px 4px 0px 4px;
			}

			.LevelThreeItemLink {
				border-radius: 0px !important;

				padding: 10px 20px 13px 35px !important;

				mat-icon,
				i {
					display: none !important;
				}

				// color: #79829c;
				color: #b6bed2;

				&:hover {
					background-color: #3c4661 !important;
					color: #fff !important;
				}
			}

			.LevelThree {
				border-radius: 0px 0px 4px 0px !important;
			}
		}

		.LevelTwoItem {
			overflow: hidden;
			width: 74px;
			transition: all 0ms;

			&:hover {
				overflow: visible !important;
				width: 330px;

				.LevelTwoItemLink {
					background-color: #30384e;

					* {
						color: #fff !important;
					}

					span {
						opacity: 1 !important;
						padding-left: 34px;
						font-size: 0.8em;
					}
				}

				.LevelThree {
					display: block !important;
					transition: all 0ms !important;
					max-height: none !important;
					position: absolute;
					opacity: 1;
					left: 74px;
					width: calc(100% - 74px);
					bottom: 0px;
					transform: translateY(100%);
					background-color: #30384e !important;

					span {
						opacity: 1 !important;
					}
				}
			}

			span {
				opacity: 0;
			}
		}

		.LevelThreeItemLink.activeLink {
			background-color: #3c4661 !important;

			&:hover {
				background-color: #3c4661 !important;
			}
		}

		span,
		i {
			transition: all 0ms !important;
		}

		.LevelOneItemLink {
			opacity: 0;
			height: 0px;
			margin: 0px;
			padding: 0px;
		}

		.MenuToggle {
			opacity: 0;
		}
	}
}

.activeNav#MenuIcon p {
	opacity: 0;
}

.activeNav#MenuIcon {
	.line-1 {
		height: 2px !important;
		transform-origin: 0px 50%;
		top: 50%;
		width: 15px !important;
		transform: translate(-50%, -50%) rotate(-45deg);
	}

	.line-2 {
		top: 18px;
		left: 21px;
		background-color: #c7cfdc;
		width: 25px;
	}

	.line-3 {
		height: 2px !important;
		transform-origin: 0px 50%;
		top: 50%;
		width: 15px !important;
		transform: translate(-50%, -50%) rotate(45deg);
	}
}

// Navigation End ---------------------------------------------------------------------

// Temporary
#level2-navigation .mat-toolbar {
	background-color: transparent !important;
	padding: 0px 20px 20px 20px;
}

// Temporary
#slide-out-nav {
	padding-top: 100px !important;
}

#slide-out-nav .mat-nav-list.mat-list-base {
	overflow: auto;
	height: calc(100vh - 275px);
}

.activeNav {
	.loginMenu {
		opacity: 1;
	}

	.navLogoContainer {
		margin: 0px 10px 10px;
		// border-radius: 5px;
		// background: #31394d;
	}

	.menuLogo {
		margin: 17px 23px 10px 23px !important;
	}

	.menuLogo.aview-logo {
		height: 19px !important;
	}

	.tools-menu {
		position: relative;
		width: 133px;
		background-color: transparent !important;

		.available-tools {
			display: flex !important;
			opacity: 0.6;
			top: -6px !important;
			background-color: transparent !important;

			img {
				cursor: pointer;
			}
		}

		.currentSection {
			.MenuToggle {
				transform: rotate(180deg) !important;
			}

			.MenuToggle + ul {
				opacity: 1 !important;
				max-height: 100vh !important;
			}
		}
	}
}

#MainContainer {
	min-height: 100vh;
}

app-distribution-group-filters-tab,
app-distribution-group-distributions-tab,
app-export-distributions-tab {
	mat-list-item.list-title-bar {
		margin-left: 0px;
		width: calc(100% - 10px) !important;
	}

	.SideList {
		padding: 10px 10px 10px 0px;
	}
}

.searchContainer {
	display: flex;
	height: 40px;
	background: #f3f6f9;
	border-radius: 5px;
}

// .mat-tree {
//   display: block;
//   width: 100%;

//   //border: 1px solid #d4dce4;
//   // box-shadow: 0px 1px 2px 0px #e3e6e9;
//   // border-radius: 4px;

//   * {
//     color: #9dabc5;
//   }

//   .mat-tree-node {
//     cursor: pointer;
//   }
.mat-list-item-content {
	i {
		font-size: 20px;
		color: #8687a7;
	}

	color: #8687a7;
}

.selected-item .mat-list-item-content {
	i:not(.disableSelectState) {
		color: #6074e2 !important;
	}

	&:before {
		content: "";
		display: block;
		position: absolute;
		top: 0px;
		z-index: 999;
		width: 100%;
		background-color: #fff;
		height: 100%;
		opacity: 0.2;
		transform: skewX(45deg) translateX(54%);
	}
}

.mat-form-field-appearance-outline .mat-form-field-flex {
	margin-top: 0px !important;
}

.mat-form-field-infix {
	width: auto;
	min-width: 180px;
}

.mat-slide-toggle-content {
	font-size: 12px;
}

.scheme-container {
	background-color: transparent !important;
}

.translateIcon {
	// position: absolute;
	// top: 28px;
	// color: #8687a7 !important;
	// right: 40px;
	// z-index: 9999;
	// font-size: 1.5em !important;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 20px;
	color: #8687a7 !important;
	right: 50px;
	border-radius: 4px;
	background: #6074e2;
	height: 32px;
	width: 32px;
	z-index: 2;
	font-size: 1.5em !important;

	img {
		width: 15px;
		margin-top: -1px;
	}

	&:after {
		display: none;
		content: attr(data-tooltip);
		background-color: #2b2b2b;
		padding: 5px 10px;
		color: #fff;
		position: absolute;
		bottom: -20px;
		left: 50%;
		transform: translateX(-50%);
		font-size: 0.4em;
		text-align: center;
		border-radius: 4px;
		white-space: nowrap;
	}

	&:hover:after {
		display: block;
	}
}

#translateBG {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	display: none;
	z-index: 999999;
	background-color: rgba(0, 0, 0, 0.6);
}

#google_translate_element {
	border: 40px solid #14181d8c;
	border-radius: 4px;
	position: fixed;
	left: 50%;
	top: 50%;
	right: auto;
	display: none;
	bottom: auto;
	z-index: 9999999;
	transform: translate(-50%, -50%);
}

.activeTranslate #google_translate_element,
.activeTranslate #translateBG {
	display: block;
}

#footer {
	position: fixed;
	bottom: 0px;
	right: 0px;
	z-index: 1;
	padding: 10px;
	font-weight: bold;
	font-size: 0.7em;
	letter-spacing: -0.03em;
	display: inline-block;
	border-radius: 4px 0px 0px 0px;

	* {
		color: $tGrey2;
		display: inline-block;
		vertical-align: middle;
		padding: 0px 5px;

		&:last-of-type {
			padding: 0px;
		}
	}
}

.ResetPasswordBtn {
	margin-top: 21px;
	height: 43px;
	margin-bottom: 19px;
}

// Should now be using .scroll-y instead (or .scroll-x)
.scroll-vertical {
	overflow-y: auto;
	overflow-x: hidden;
	float: left;
	height: 100%;
}

.dialog-container {
	display: flex;
	flex-direction: column;
}

.SideList .list-container-below-header .mat-list-item.selected-item:hover,
.mat-list-option:hover,
.mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
	background: rgb(227, 235, 240) !important;
}

.listIcons {
	* {
		display: inline-block;
		vertical-align: middle;
	}

	i {
		font-size: 1em;
		width: 1em;
		height: 1em;
		line-height: 1em;
	}

	.innerBall {
		font-size: 80% !important;
		width: 1em;
		height: 1em;
		top: 50%;
		line-height: 1em;
		left: 50%;
		transform-origin: 50% 50%;
		transform: translate(-50%, -50%);
	}
}

.mat-chip-list-wrapper {
	margin: 0px !important;
}

.list-container-below-header {
	.mat-chip-list-wrapper {
		flex-direction: column !important;
		width: 100%;
	}
}

.row-chip-list .mat-chip-list-wrapper {
	flex-direction: row !important;
	flex-wrap: wrap !important;

	.mat-chip-input {
		background: #fff;
		height: 34px;
		padding: 0px 10px;
		border-radius: 4px;
		margin-bottom: 0px;
	}
}

.DistributionsTable {
	.listIcons {
		position: relative;
		bottom: auto;
		right: auto;

		.innerBall {
			font-size: 136% !important;
		}

		.versionNumber {
			font-size: 0.5em !important;
		}

		* {
			font-size: 152%;
		}
	}
}

/* Header and Nav */
#header {
	//z-index: 800;
	width: 100%;
	top: 0px;
	left: 0px;
	padding-left: 0px;
	transition: all 350ms;

	.mat-sidenav {
		width: 400px;
		position: fixed;
		max-width: 100%;
		height: 100vh;
		top: 0px;
		padding: 20px 20px 20px 0px;
		overflow: hidden;
		border-radius: 0px !important;

		.mat-list-item-content {
			&:before {
				transition: all 350ms;
				content: "";
				height: 0%;
				opacity: 0;
				width: 5px;
				display: block;
				background-color: $purple;
				position: absolute;
				left: 0px;
				top: 50%;
				transform: translateY(-50%);
			}

			&:hover:before {
				height: 100%;
				opacity: 1;
			}
		}
	}

	.mat-nav-list .mat-list-item {
		height: auto !important;
	}

	.MenuTitle strong {
		letter-spacing: 0px !important;
	}

	.mat-nav-list .mat-list-item:hover {
		background-color: transparent !important;
	}
}

.mat-drawer-inner-container {
	overflow: hidden !important;
}

.mat-drawer-scrollable .mat-drawer-inner-container {
	overflow: auto !important;
}

/*** Main Side Nav Overides ***/
.mat-toolbar {
	background: #ffffff !important;
	transition: all 350ms;
	font-size: 1em !important;
}

.MenuTitle {
	background-color: #fff !important;
	padding-top: 20px !important;
	padding-bottom: 20px !important;
	position: relative;

	color: #1d1f1f !important;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 2px !important;
	padding-left: 20px !important;
	padding-right: 20px !important;

	* {
		color: #1d1f1f !important;
	}
}

.mat-menu-content {
	padding: 0px !important;
	float: left;
	width: 100%;
}

.mat-icon.mat-primary {
	color: #6074e2 !important;
}

app-extract-default-property-value-rules {
	.mat-list-base {
		padding: 0px 0px !important;
	}

	.pr10 {
		padding-right: 0px !important;
	}
}

.SideList .mat-nav-list .mat-list-item {
	height: auto !important;
	min-height: 36px;
	position: relative;

	.mat-list-item-content {
		background-color: inherit;
		min-height: 36px;
		padding: 7px 10px !important;
	}
}

.SideBarScroll {
	height: 100%;
	width: 100%;
	padding-right: 0px;
	display: flex;
	flex-direction: column;
	overflow: auto;
}

// Access Control Users Lists
.short-list .SideBarScroll {
	max-height: calc(100vh - 450px);
}

.long-list mat-list {
	max-height: calc(100vh - 400px);
}

app-exporter-add-dialog,
app-dataset-details-tab {
	app-list-search-header .mat-icon-button {
		position: absolute;
		right: 15px;
		top: 10px;
	}

	.mat-list-base {
		padding: 0px 0px;
	}

	mat-list.scroll {
		padding: 0px 10px !important;
	}

	.searchContainer.closedList {
		overflow: visible !important;
		height: auto !important;
	}

	.SideBarToggle {
		display: none !important;
	}
}

.mat-list-base .mat-list-item .mat-line {
	white-space: initial !important;
}

app-exporter-destinations-tab {
	.searchHeaderContainer {
		padding-left: 0px !important;
	}

	mat-list {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
}

#arrow-decision-outline {
	width: 35px !important;
	margin-left: -3px;
	margin-top: -2px;
	height: 35px !important;
}

/* Mat field bug fix for older elements */
.mat-form-field-appearance-outline .mat-form-field-wrapper {
	padding-bottom: 10px !important;
}

.mat-form-field-appearance-outline .mat-select {
	min-height: 18px;
}

.mat-form-field-appearance-outline
	.mat-form-field-type-mat-select
	.mat-form-field-infix {
	height: 64px;
	padding: 0.8em 0 1em 0 !important;
}

.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
	transform: translateY(-3px) !important;
}

// Disabled Checkboxes:
.mat-checkbox-disabled {
	opacity: 0.5;
}

.knowledgeBaseIcon {
	color: #3fc2cc !important;
	cursor: pointer;
}

app-add-edit-product-class-dialog .mat-form-field-infix {
	width: 100%;
}

// Chip styles
.mat-standard-chip {
	overflow: hidden;
	height: auto !important;
}

app-product-extract-definitions .product-property-allocation-chip,
app-product-property-index .mat-chip.mat-standard-chip,
app-matcher-element .mat-chip.mat-standard-chip {
	background-color: #fff !important;
}

.mat-chip {
	position: relative;
	border-color: #e8edf4;
	border-width: 1px !important;
	border-style: solid !important;
	border-radius: 5px !important;
	//overflow: visible !important;
	background-color: #f3f6f9;

	.chipBG {
		position: absolute;
		top: 0px;
		left: 0px;
		height: 100%;
		width: 100%;
		opacity: 0.2;
	}
}

.cdk-drag .mat-chip,
.draggable-chip .mat-chip,
.draggable-chip.mat-chip {
	transition: all 150ms;
	transform: scale(1);
	cursor: move;
	//border-bottom: 8px solid #8687a778;

	&:hover {
		transform: scale(1.03);
	}
}

.dropZone.cdk-drop-list-dragging,
.dropZone.cdk-drop-list-receiving {
	border-color: $blue !important;
}

app-product-extract .mat-list-item,
app-product-extract .mat-nav-list .mat-list-item:hover,
app-extract-default-property-value-rules .mat-list-item,
app-extract-default-property-value-rules .mat-nav-list .mat-list-item:hover {
	background-color: transparent !important;
	position: relative;
}

.mat-option:before,
app-product-extract .mat-list-item:before {
	content: "";
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1;
	top: 0px;
	left: 0px;
	cursor: pointer;
}

app-product-extract
	.SideList
	.mat-nav-list
	.mat-list-item
	.mat-list-item-content {
	padding: 0px !important;
}

.doubleColumnList {
	.displaySelectedMobileList {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: stretch;
		align-content: stretch;
	}

	.mat-list-item {
		flex: 1;
		max-width: 50%;
		min-width: 300px;
	}
}

.chipField .mat-form-field-underline {
	display: none !important;
}

.chipField input {
	border-radius: 3px;
	font-weight: 600;
	margin-top: 0px !important;
	font-size: 12px !important;
	line-height: 16px;
	height: 30px;
	border: 2px solid rgb(64, 119, 162);
	padding: 0px;
	padding: 10px 3px !important;
}

.assetContainer .mat-mini-fab.mat-accent {
	background-color: transparent !important;

	* {
		color: #8687a7 !important;
	}
}

app-rule-maker-chips .mat-form-field .mat-form-field-infix {
	border-top: none !important;
}

.action-buttons {
	overflow: visible !important;
}

.logContainer {
	app-infinity-scroller .scrollerItem:nth-child(odd) {
		.logEntry {
			background-color: #fff !important;
		}
	}
}

app-user-details-tab {
	.card {
		box-shadow: none !important;
	}
}

.mat-option.mat-active,
.mat-option.mat-selected .propertyChip {
	border-color: $blue !important;
	border-width: 3px !important;
}

.mat-select-panel {
	max-height: 380px !important;
}

.mat-step-header .mat-icon {
	color: #fff !important;
}

app-product-asset-view {
	.fabButtonIcon {
		box-shadow: none !important;
	}
}

.mat-cell {
	color: #8687a7 !important;
	font-weight: normal;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 5px;
	padding-right: 5px;
	line-height: 1.5em;
	font-size: 12px !important;
	word-break: break-word;
}

// .dragHandle {
//   cursor: move;
//   width: 26px;
//   position: absolute;
//   left: 6px;
//   opacity: 0.68;
//   font-size: 26px;
//   top: 50%;
//   display: none;
//   transform: translateY(-50%);
// }

// .dragHandle span {
//   display: block;
//   float: left;
//   margin: 2px;
//   background-color: #8687a7;
//   width: 3px;
//   height: 3px;
// }

.cdk-drag .mat-chip .dragHandle {
	display: block !important;
}

.disable-selection .mat-chip,
.cdk-drag:not(.dragChipDisabled) .mat-chip {
	padding-left: 35px !important;
	// min-height: 70px !important;
	border: 1px solid #e8edf4;
	box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.08),
		0 3px 6px 0 rgba(0, 0, 0, 0.12);
}

.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
	background: #fff !important;
}

mat-row,
mat-header-row,
mat-footer-row,
th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
	border-bottom-color: #e3ebf6 !important;
}

.mat-header-cell,
.mat-sort-header-stem,
button.mat-sort-header-button {
	color: #363f67 !important;
	font-weight: normal !important;
}

.boxContainer {
	background: #eef2f6;
	border: 1px solid #dde3ec;
	border-radius: 5px;
	padding: 20px;
}

.innerCard {
	padding: 20px;
	border: 1px solid #d4dce4;
	box-shadow: 0px 1px 2px 0px #e3e6e9;
	border-radius: 5px;
	background: #fff;
}

app-index-list.innerCard {
	padding-top: 20px !important;
}

.joiningKeyButton {
	button {
		height: 100%;
	}
}

.mat-tab-body-wrapper {
	flex: 1;
	box-sizing: border-box;
	box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
	border-radius: 0px 5px 5px 5px;
	position: relative;
	background-color: #ffffff !important;
}

.infoTable {
	width: 100%;

	thead {
		display: none;
	}
}

.treeThenProductViews {
	.mat-tree {
		border-radius: 4px 0px 0px 4px;
		border-right: none;
	}

	app-category-products-view {
		border-radius: 0px 4px 4px 0px;
	}
}

.productViewsThenTree {
	.mat-tree {
		border-radius: 0px 4px 4px 0px;
		border-left: none;
	}

	app-category-products-view {
		border-radius: 4px 0px 0px 4px;
	}
}

/* Transparent overlay on app-select-with-search
*  select field so you can click on it when chips are injected
*/
app-select-with-search .mat-select-trigger:after {
	content: "";
	display: block;
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}

.rightTable,
.leftTable {
	background-color: #fff;
}

// My Library Views Compare mode responsive:
.main-form-button {
	display: none;
}

.mobile-aview-show,
.mobile-compare-reaction-menu {
	display: none;
}

.mobile-aview-hide {
	display: block;
}

.compareModeEnabled {
	.mobile-aview-show {
		display: flex !important;
	}

	.mobile-aview-hide {
		display: none !important;
	}

	.rightTable,
	.leftTable {
		min-width: 100%;
		background-color: #fff;
	}

	.mobile-aview-hide,
	.rightTable tr.mat-header-row {
		display: none !important;
	}

	.rightTable .mat-header-cell {
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	}

	.tableSectionContainer {
		.tableSection {
			margin: 0px !important;
		}

		.tableSection:nth-child(even) {
			padding-left: 0px !important;
		}

		.tableSection:nth-child(odd) {
			padding-right: 0px !important;
		}

		display: flex !important;

		flex-direction: column;
	}

	.tableSection {
		flex: initial !important;
	}

	.tableSection.leftTable,
	.leftTable {
		margin: 0px !important;
		border-bottom: none !important;
		border-radius: 10px 10px 0px 0px !important;
	}

	.tableSection.rightTable,
	.rightTable {
		margin: 0px !important;
		border-top: none !important;
		border-radius: 0px 0px 10px 10px !important;
	}

	.full-width-data {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}

	.productViewFullTitle {
		font-size: 16px !important;
	}

	.leftImages {
		min-width: 170px !important;
	}

	// aView filter chips:
	.left-top-bar,
	.right-top-bar {
		overflow: visible;
	}

	.show-filters-button {
		display: flex !important;
		height: 60px;
		line-height: 60px;
	}

	.chip-bar {
		min-width: 300px;
		position: absolute;
		display: none;
		padding: 10px 10px 10px 10px;
		background: #fff;
		border-radius: 0px 0px 5px 5px;
		bottom: 0px;
		transform: translateY(100%);
		z-index: 900;
		flex-wrap: wrap;
		border: 1px solid #e4edf0;
		box-shadow: 0px 5px 5px 0px #d4dce4;

		&.right-bar {
			right: -10px;
			justify-content: flex-end;
		}

		&.left-bar {
			left: -10px;
		}
	}

	.chip-container {
		max-width: 50px;

		&.right-chip-container {
			flex-direction: row-reverse;
		}

		&:hover .chip-bar {
			display: flex;
		}
	}
}

// Selections in compare mode

.compare-mode-title {
	white-space: nowrap;
	text-overflow: ellipsis;
	display: inline-block !important;
}

.main-form-button {
	display: flex !important;
}

.aview-main-form-container {
	flex: 1;
	overflow: visible;
}

.compare-mode-title {
	display: inline-block !important;
	height: 100%;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	vertical-align: middle;

	app-export-version-ball,
	span {
		vertical-align: middle;
		display: inline-block !important;
	}
}

.top-bar {
	// height: 80px !important;

	.aview-main-form-container,
	.pt5 {
		padding-top: 0px;
	}
}

// .aview-main-form {
//   position: absolute;
//   flex-direction: column;
//   background: #fff;
//   padding: 20px;
//   z-index: 2;
//   border-radius: 8px;
//   left: 25px;
//   bottom: -11px;
//   transform: translateY(calc(100% - 20px));
//   display: none;
//   z-index: 9999 !important;
//   align-items: start !important;
//   box-shadow: 0px 5px 20px 0px #435d7b26 !important;

//   app-select-with-search-grouped,
//   .mat-form-field {
//     margin-left: 0px !important;
//   }

//   app-select-with-search-grouped {
//     width: 100% !important;
//   }

//   &:before {
//     content: "";
//     display: block;
//     position: absolute;
//     top: -17px;
//     left: 3px;
//     border: 10px solid transparent;
//     border-bottom-color: #fff;
//     z-index: 90;
//   }

//   &:after {
//     content: "";
//     display: block;
//     position: absolute;
//     width: 100%;
//     height: 20px;
//     top: 0px;
//     transform: translateY(-100%);
//     left: 0px;
//     z-index: 90;
//   }
// }

.aview-main-form-container {
	border: none;
	padding-top: 5px;
	background: transparent;

	&.active {
		.aview-main-form {
			display: flex;
		}
	}
}

.sectionIconSmall,
.sectionIconLarge {
	display: inline-block;
	vertical-align: middle;
	margin-right: 5px;
}

.sectionIconLarge {
	width: 25px;
	border-radius: 4px;
	height: 25px;
	margin-right: 10px;
}

.sectionIconSmall {
	width: 12px;
	height: 12px;
}

.sectionIcon10 {
	margin-right: 5px;
	width: 10px;
	height: 10px;
}

.mat-nav-list .mat-icon-no-color {
	color: #8687a7 !important;
}

.firstTab {
	border-top-left-radius: 0px !important;
}

.footerLoggedOut {
	.errorMessage {
		display: none !important;
	}
}

.ag-header-cell-text {
	color: #6a6b7c;
}

.mat-form-field-disabled.mat-form-field-appearance-outline {
	.mat-form-field-outline {
		background: #f3f6f980;
	}
}

.mat-form-field-subscript-wrapper {
	top: calc(100% - 13px) !important;
	position: relative !important;
}

.class-id-chip {
	background-color: #e0e5e9 !important;
	border: 2px solid #e0e5e8 !important;
	color: rgb(109 110 121) !important;
}

.mat-raised-button[disabled] {
	min-height: 34px;
}

.stageIndexList {
	.searchHeaderContainer {
		padding-left: 45px;
	}
}

.smallGrid {
	.cardContainer {
		max-width: 240px !important;
	}
}

.LoggedIn {
	transition: all 350ms;
	padding-left: 115px;
	padding-right: 40px;

	.loggedInHide {
		display: none !important;
	}
}

// Activity Monitor

.aHubToast .mat-progress-spinner circle,
.aHubToast .mat-spinner circle {
	stroke: #fff !important;
}

// aView overrides:
app-aview-container {
	.mat-tab-body-wrapper {
		box-shadow: none !important;
		margin: 0px !important;
	}

	.mat-tree {
		border: none !important;
		box-shadow: none !important;
	}
}

.aview-container-0 {
	z-index: 9;
}

.mat-expansion-panel-body {
	padding: 20px !important;
}

app-aview-product-filter {
	.mat-expansion-panel-header {
		background: transparent !important;
		min-height: 40px !important;
		height: 40px !important;
		border-radius: 0px !important;
		font-size: 13px !important;
	}

	.panel-title {
		color: #373f67;
	}

	.mat-expansion-panel-body {
		overflow: hidden !important;
		padding: 0 !important;
		flex: 1;
		display: flex;

		span {
			font-size: 13px !important;
		}
	}

	.SideList .mat-nav-list .mat-list-item {
		background-color: #f5f8fa;
	}

	.searchHeaderContainer {
		position: absolute;
		width: 100px;
		right: 0px;
		top: -3px;

		.card-sub-title {
			display: flex;
			justify-content: flex-end;

			.flex-1 {
				flex: initial;
			}
		}
	}

	.mat-expansion-panel-spacing {
		margin-top: 0px !important;
	}

	.list-title-bar.text-list-title.pb10 {
		padding-top: 10px;
	}

	.searchContainer {
		padding-right: 100px;
	}

	.SideList .mat-nav-list .mat-list-item .mat-list-item-content {
		padding: 0px !important;
	}

	.mat-expansion-panel-content {
		display: flex;
		overflow: hidden !important;
	}

	.mat-expansion-panel {
		font-size: 15px !important;
		border: 0px !important;
	}

	app-index-list {
		height: auto !important;
	}

	.mat-list-item {
		margin: 0px !important;
		background-color: #fff;
	}

	.selected-filters {
		font-size: 0.8em;
		font-weight: normal;
		color: #6b6c77;
		padding-top: 4px;
	}

	.mat-content {
		overflow: visible !important;
	}

	.mat-expanded .mat-expansion-panel-header-title .panel-title {
		color: #6074e2 !important;
	}
}

app-aview-container .mat-tab-label {
	padding: 0 0px !important;
	min-width: 110px !important;
}

// Toast user icon overide - Temporary iuntil better solution
.inner-toast {
	.user-icon {
		opacity: 1 !important;
	}

	.user-icon.current-user .user-icon-text {
		filter: none !important;
		color: #fff !important;
	}

	.user-icon-bg {
		filter: none !important;
		background: #fff !important;
	}
}

//
app-asset-file-product-matcher-wizard-dialog {
	.mat-chip-list-wrapper {
		width: 100%;
	}
}

.showForaWorkbook {
	display: none;
}

.page-aworkbook {
	overflow: auto !important;

	.hideForaWorkbook {
		display: none;
	}

	.showForaWorkbook {
		display: block;
	}
}

.mat-option-disabled {
	opacity: 0.5;
}

// app-product-filter-list-item.cdk-drag-placeholder,
// app-product-filter-list-item.cdk-drag-dragging {
//   display: none !important;
// }

// app-product-filter-list-item.cdk-drag-preview {
//   width: 420px !important;

//   .filterItem {
//     box-shadow: 0 7px 14px 0 rgb(60 66 87 / 8%);
//   }
// }

.mat-chip:active {
	transition: none !important;
	box-shadow: none !important;
}

// Portal style menu. Add this to menu: backdropClass="portal-menu"
.portal-menu + * .cdk-overlay-pane > div.mat-menu-panel {
	background: #fff;
	margin-top: 13px;
	margin-left: -25px;
	max-width: none !important;
	border-radius: 8px !important;
	box-shadow: 0px 10px 40px 0px #ababab85;

	&:before {
		content: "";
		display: block;
		position: absolute;
		top: -7px;
		left: 3px;
		border: 10px solid transparent;
		border-bottom-color: #fff;
		z-index: 90;
	}

	.mat-menu-content {
		padding: 0px !important;
	}

	mat-tree-node {
		padding-left: 0px !important;
	}

	.portal-menu-list {
		// background: #e8edf3;
		background: #f5f8fa;
		font-size: 12px;
		max-height: 300px;
		// overflow: hidden;
		//border-radius: 4px;
		border-radius: 0px 0px 4px 4px;

		* {
			color: #8287a6;
		}

		&.shadow-list {
			border-radius: 4px;
			box-shadow: inset 0px 0px 10px 0px #e2e7ef;
		}
	}

	.portal-menu-top-bar {
		padding: 10px 20px;
	}

	.portal-menu-item {
		background: #fff;
		padding: 10px 15px;
		border-radius: 8px;
		margin: 10px 0px;
		box-shadow: 0px 7px 10px 0px #c5d0de73;
	}

	.portal-menu-item-remove {
		i {
			color: #db478a;
			cursor: pointer;
			font-size: 15px;
		}
	}

	.portal-menu-item-add {
		i {
			color: #6074e2;
			cursor: pointer;
			font-size: 15px;
		}
	}

	.menu-title {
		color: #363f67;
	}

	.search-button {
		position: absolute;
		right: 10px;
		font-size: 19px;
		top: 6px;
		color: #8687a7 !important;
	}

	.search-input {
		position: relative;
		border: none !important;
		background: #f3f6f9;
		width: 150px !important;
		min-width: 150px !important;
		font-size: 12px;
		border-radius: 4px !important;
		padding: 10px 20px !important;

		&::placeholder {
			color: #b2bcca !important;
		}
	}

	.search-bar {
		position: relative;
		width: 150px;
		z-index: 99;

		.search-button {
			color: #8687a7 !important;
		}
	}

	.menu-count {
		font-size: 9px;
		color: #8487a6;
	}
}

.reaction-menu + div .mat-menu-panel {
	background: #fff;
	margin-bottom: 10px;
	margin-right: -8px;
	overflow: hidden;
	min-width: 0px;
	padding-right: 5px;
	min-height: 0px !important;
	max-width: none !important;
	border-radius: 20px !important;
	box-shadow: 0px 10px 40px 0px #ababab85;

	app-reaction-icon {
		z-index: 9;
		position: relative;
		margin: 5px 0px 5px 5px;
		display: block;
	}

	&:before {
		content: "";
		display: block;
		position: absolute;
		bottom: -7px;
		right: -3px;
		border: 10px solid transparent;
		border-top-color: #fff;
		z-index: 8;
	}
}

.mat-radio-button {
	display: flex;
}

.mat-radio-label-content {
	display: flex;
	flex: 1;
	white-space: initial;
	font-weight: 600;
	overflow: hidden;
}

// New Tabs
.mat-tab-label-content {
	font-weight: 600 !important;
}

.mat-tab-label {
	font-size: 12px;
	font-weight: normal !important;
	border-radius: 5px 5px 0px 0px;
}

.mat-tab-link {
	font-weight: normal !important;
}

.mat-table {
	background: transparent !important;
}

.mat-chip-list {
	// background: #e8edf49c;
	display: flex;
	border-radius: 5px;
	padding: 15px 9px !important;
}

app-class-property-mapper {
	.mat-chip-list {
		padding: 0px !important;
	}
}

.tab-body {
	background: #e8edf47d;
	padding: 20px;
}

.mat-menu-item {
	color: #8687a7 !important;
	font-size: 12px !important;

	transition: all 0.35s;

	&:hover {
		color: #6074e2 !important;
	}
}

// Bottom toolbar styling
.toolbar-container {
	position: absolute;
	bottom: 40px;
	right: 40px;
}

.toolbar-text-button,
.toolbar-button {
	height: 32px;
	width: 32px;
	border-radius: 4px;
	color: #fff;
	background: #6074e2;
	font-size: 14px;
	position: relative;
	border: none !important;
	cursor: pointer;

	i {
		color: #fff;
	}

	&.toolbar-button-light {
		color: #6074e2 !important;
		border: none !important;
		background-color: #f3f6fa !important;

		i {
			color: #6074e2;
		}
	}

	&.toolbar-button-light.active-button {
		color: #fff !important;
		border: none !important;
		background-color: #6074e2 !important;

		i {
			color: #fff;
		}
	}

	// &:before {
	//   content: "";
	//   display: block;
	//   position: absolute;
	//   top: 0px;
	//   width: 100%;
	//   background-color: #fff;
	//   height: 100%;
	//   opacity: 0.07;
	//   transform: skewX(45deg) translateX(54%);
	// }

	&:disabled {
		background: #92a1ef;
		pointer-events: none;
	}
}

.toolbar-text-button {
	width: auto;
	padding-left: 10px;
	padding-right: 10px;

	i {
		margin-right: 5px;
	}

	span {
		font-size: 12px;
	}
}

.toolbar-button-left {
	border-right: 1px solid #b6bde6 !important;
	border-radius: 4px 0px 0px 4px;
}

.toolbar-button-center {
	border-radius: 0px;
	border-right: 1px solid #b6bde6 !important;
}

.toolbar-button-right {
	border-radius: 0px 4px 4px 0px;
}

.filter-title .mat-badge-content {
	background: #6074e2 !important;
}

.dark-tabs {
	.mat-tab-body-content,
	.mat-tab-label-active {
		background-color: #eef2f6 !important;

		.mat-form-field-outline {
			background: #fff !important;
			border-radius: 5px;
		}

		fieldset {
			border-color: #e8edf333;
			background: #fff;
		}
	}
}

app-product-class-matrix-dimension .mat-chip-list {
	background: transparent !important;
}

.selection-reaction-count app-reaction-icon {
	.bi-hand-thumbs-up {
		font-size: 13.8px !important;
	}
}

app-share-selection,
app-share-selection-dialog {
	form {
		.mat-form-field-appearance-outline .mat-form-field-outline {
			border-radius: 4px 0px 0px 4px !important;
		}

		app-raised-busy-button button {
			height: 48px !important;
			margin-top: -3px !important;
		}

		app-raised-busy-button {
			i {
				font-size: 20px;
			}

			button.mat-focus-indicator.tac.mat-raised-button.mat-button-base.mat-primary {
				border-radius: 0px 4px 4px 0px !important;
			}
		}
	}
}

.copy-clipboard {
	.mat-form-field-outline {
		color: #f1f3ffa6 !important;
		background: #3c4d5f !important;
	}

	.mat-form-field .mat-input-element {
		color: #f1f3ffa6 !important;
	}
}

.mat-chip {
	.dragHandle {
		display: none;
	}
}

.cdk-drag {
	.mat-chip {
		.dragHandle {
			display: flex;
			position: absolute;
			// left: 0px;
			top: 50%;
			transform: translateY(-50%);
			font-size: 20px;
			left: 10px;
		}
	}
}

app-class-property-mapper {
	.mat-drawer-backdrop.mat-drawer-shown {
		background-color: rgb(255 255 255 / 60%);
	}

	.mat-drawer.mat-drawer-end {
		border: 1px solid #e8edf3 !important;
		border-radius: 10px;
		overflow: hidden;
		padding: 10px;
		margin: 10px;
		box-shadow: 0px 0px 10px 0px #00000014 !important;
	}
}

.col-chip-list .mat-chip-list-wrapper {
	flex-direction: column;
	width: 100%;
}

#list-view {
	display: none;
	padding: 20px 20px 20px 0px;
}

#category-search {
	display: none;
}

.active-list-view {
	.col-7 {
		width: 500px;
		//max-width: 400px;
	}

	#category-search {
		display: flex;
	}

	.col-5 {
		flex: 1;
	}

	//app-category-sorting-tab .scroll-y:first-of-type,
	app-category-rules-tab .firstTab {
		flex-direction: row;
		flex-wrap: wrap;
		padding: 20px 10px 20px 20px !important;

		.innerCard {
			margin: 5px !important;
			min-width: 550px;
		}
	}

	app-category-sorting-tab .scroll-y:first-of-type {
		// padding: 0px !important;
	}

	#list-view > .tree-item:first-child {
		border: solid 12px #c0c;
	}

	#list-view > .full-height > .full-height > .tree-item:first-of-type:after,
	#list-view > .full-height > .full-height > .tree-item:first-of-type:before {
		display: none;
	}

	.displayTagsControl,
	.titleUnderline {
		display: none !important;
	}

	#list-view {
		display: flex;
	}

	#tree-view {
		display: none;
	}
}
