/* Responsive */

@media screen and (max-width: 1110px) {
	.clearLarge {
		clear: both;
		width: 100% !important;
		flex-grow: initial !important;
		flex-shrink: initial !important;
		flex-basis: initial !important;
		float: left;
		display: block !important;
		margin: 0px !important;
	}

	.text-top-nav-menu {
		font-size: 15px;
	}
}

@media screen and (max-width: 1060px) {
	.clearMid {
		clear: both;
		width: 100% !important;
		flex-grow: initial !important;
		flex-shrink: initial !important;
		flex-basis: initial !important;
		display: block !important;
		float: left;
		margin: 0px !important;
	}

	.noPadMid {
		padding: 0px !important;
	}

	.nolrPadMid {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}

	.dashboardContainer {
		padding: 20px !important;
		height: auto !important;

		.col-6 {
			height: auto !important;
			padding: 0px !important;
			margin-top: 20px !important;
		}
	}
}

@media screen and (max-width: 770px) {
	app-client-library-tab {
		.pl20.clearSmall {
			padding-left: 0px;
		}
	}

	.joiningKeyButton {
		display: none !important;
	}

	.clearSmall {
		clear: both;
		width: 100% !important;
		flex-grow: initial !important;
		flex-shrink: initial !important;
		flex-basis: initial !important;
		display: block !important;
		margin: 0px !important;
		float: left;
		border: none !important;
	}

	.hideSmall {
		display: none !important;
	}

	.nolrPadSmall {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}

	.text-top-nav-menu {
		display: none;
	}

	#TopBar {
		padding: 90px 20px 20px 117px;
	}
}

/** Login screens Mobile **/

@media screen and (max-width: 770px) {
	.flex-container.banner {
		padding-top: 10px !important;

		.mat-icon {
			font-size: 50px !important;
			width: 50px !important;
			height: 50px !important;
		}

		.mat-card-title {
			padding-bottom: 10px !important;
		}
	}

	#level2-content.library-level2-content {
		overflow: auto !important;
	}
}

@media screen and (max-width: 660px) {
	/* Dialog Container */
	.mat-dialog-container {
		min-width: 0px !important;
	}

	.mat-dialog-title,
	.card-title {
		font-size: 18px !important;
	}

	#footer {
		span.notranslate {
			font-size: 15px !important;
		}
	}
}

@media screen and (max-width: 550px), screen and (max-height: 700px) {
	.text-top-nav-menu {
		font-size: 3vw !important;
	}

	.inactiveNav#Navigation {
		max-width: 0;
		overflow: hidden;
	}

	.LoggedIn {
		padding-left: 20px !important;
		padding-right: 20px !important;
	}

	#MenuIcon {
		position: absolute;
		color: #363f6a;
	}

	.activeNav#MenuIcon {
		position: fixed;
		color: #fff;
	}

	.activeNav#MenuIcon span,
	.activeNav#MenuIcon:hover span {
		background-color: #fff !important;
	}

	.mainLogo {
		top: 20px;
		height: 39px;
	}

	.inactiveNav#Navigation .mainLogo {
		left: 50%;
		transform: translateX(-50%);
	}

	#MainAppContainer:not(.LoggedIn) {
		padding-right: 0px !important;
	}
}

@media screen and (max-height: 630px) and (max-width: 700px) {
	.text-top-nav-menu {
		font-size: 3vw !important;
	}

	.flex-container.banner {
		padding-top: 10px !important;

		.mat-icon {
			font-size: 50px !important;
			width: 50px !important;
			height: 50px !important;
		}

		.mat-card-title {
			padding-bottom: 10px !important;
		}
	}
}

// The point where the main containers stack and full page scroll needs to occur
@media screen and (max-width: 1350px) {
	.mat-dialog-container {
		padding: 10px 20px 20px 20px !important;
	}

	body,
	html {
		height: auto !important;
	}

	app-user-export-distributions-tab.full-height {
		height: auto;
	}

	.extractScroll {
		max-height: none !important;
	}

	#level2-navigation {
		clear: both;
		float: left;
	}

	// Index List Responsive
	.responsiveList .SideBarToggle {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		display: inline-block !important;
	}

	.responsiveList .SideBarScroll {
		max-height: 0vh;
		overflow: hidden !important;
		margin: 0px !important;
		opacity: 1;
	}

	.responsiveList .openList.SideBarScroll {
		max-height: 100vh !important;
		overflow-y: auto !important;
		margin: 0px !important;
		// margin-top: 10px !important;
		opacity: 1 !important;
		padding: 0px 10px 10px 10px !important;
	}

	.responsiveList .displaySelectedMobileList {
		.SideBarScroll {
			max-height: 100vh;
		}

		.mat-list-item {
			display: none !important;
			transition: all 0ms;
		}

		.mat-list-item.selected-item {
			display: block !important;
			margin-bottom: 10px;
		}

		.openList.SideBarScroll {
			.mat-list-item {
				display: block !important;
				margin: 3px 0px !important;
			}
		}
	}

	.joining-key-bar .top-right-widgets {
		right: 80px;
	}

	.responsiveList .searchContainer.closedList {
		overflow: hidden;
		height: 0px;
	}

	app-list-dialog {
		.searchContainer.closedList {
			height: auto;
		}
	}

	.property-allocated-panel {
		margin-bottom: 15px !important;
	}

	.responsiveList app-list-search-header.closedList {
		.searchButton,
		.searchInput {
			visibility: hidden;
			height: 0px;
		}
	}

	app-exporter-add-dialog {
		app-list-search-header.closedList {
			.searchButton,
			.searchInput {
				visibility: visible;
				height: auto;
			}
		}
	}

	app-extract-product-property-filters {
		margin-top: 40px;
	}

	.nolrPadMob {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}

	app-extract-assets-tab .fabColumn {
		position: relative;
		display: block;

		div,
		app-fab-icon-busy-button {
			display: block !important;
			float: left !important;
		}
	}

	app-user {
		margin-bottom: 20px;
	}

	#level2-content,
	app-distribution-group,
	app-work-group,
	#level1-content,
	.mobClear,
	#level3-item-selection,
	#level2-item-selection,
	app-user-export-distributions-tab,
	app-distribution,
	app-work-group-details-tab,
	app-work-group-users-tab,
	app-version-details-tab,
	app-version-creation-tab,
	app-version-upgrading-tab,
	app-version-clients-tab,
	app-distribution-group-details-tab,
	app-distribution-group-users-tab,
	app-distribution-group-distributions-tab,
	app-distribution-group-filters-tab,
	app-export-details-tab,
	app-export-versions-tab,
	app-export-distributions-tab,
	app-export-legacy-ref-tab,
	app-dataset-details-tab,
	app-exporter-details-tab,
	app-exporter-destinations-tab,
	app-exporter-mapping-tab,
	app-exporter-resources-tab,
	app-exporter-delivery-tab,
	app-resource-pack-details-tab,
	app-resource-pack-content-tab,
	app-client-details-tab,
	app-client-usage-tab,
	app-client-configuration-tab,
	app-user-details-tab,
	app-classification,
	app-properties,
	app-product-extract-definition,
	app-product-extract,
	app-welcome-home,
	app-welcome-software,
	app-user-details,
	app-my-workgroups,
	app-work-groups,
	app-products,
	app-product-extract-definitions,
	app-extract-assets-tab,
	app-extract-data-in-out-tab,
	app-product-extract-data,
	app-datasets,
	app-exporters,
	app-exports,
	app-resource-packs,
	app-distribution-groups,
	app-usage-quotas,
	app-integration,
	app-client-config-tab,
	app-clients,
	app-users-admin,
	app-versions,
	app-debug,
	app-system-databases,
	app-system-control,
	app-space-1,
	app-space-2,
	app-space-3,
	app-space-4,
	app-space-5,
	app-export {
		display: block !important;
		width: 100% !important;
		clear: both;
		max-height: 100%;
		height: auto !important;
		float: left;
		flex-basis: 100% !important;
		margin-left: 0px !important;
		margin-right: 0px !important;
	}

	app-user-export-distributions-tab-distribution-group-wrapper {
		margin-top: 20px;
		display: block;
	}

	app-user-export-distribution {
		margin-top: 30px;
		width: 100% !important;
	}

	#level3-item-view {
		padding-left: 0px;
		margin-top: 30px;
	}

	#level2-content.card {
		padding-left: 20px !important;
		padding-right: 20px !important;
	}

	.detailsImageContainer {
		padding-bottom: 20px;
	}

	.usageContainer {
		.pl20.pr20.scroll-y {
			padding-left: 0px;
			padding-right: 0px;
		}

		.titleUnderline {
			justify-content: center;
			align-items: center;
			text-align: center;

			& + .justify-start {
				justify-content: center;
			}
		}
	}

	app-users-distribution,
	#level2-navigation {
		display: block !important;
		width: 100% !important;
		clear: both;
		height: auto !important;
		float: left;
		flex-basis: 100% !important;
		margin-left: 0px !important;
		margin-right: 0px !important;
	}

	#level2-content-no-title,
	#level1-content {
		overflow: visible !important;
		height: auto !important;
	}

	.cardScroll {
		height: auto !important;
		max-height: none !important;
	}

	#level2-content {
		padding-left: 0px;
		padding-right: 0px;
		overflow: visible !important;
		height: auto !important;
		padding-bottom: 30px;

		.SideList {
			padding: 0px 0px !important;
		}

		app-list-search-header {
			padding-right: 0px !important;
		}
	}

	.list-title-bar {
		width: 100% !important;
	}

	#level2-item-selection {
		height: auto !important;
		margin-bottom: 20px !important;
	}

	app-distribution {
		.col-12.clear.pb40.pl20 {
			padding-left: 0px !important;
		}
	}

	#level2-content.card.MobRemoveRightPadding,
	.MobRemoveRightPadding {
		padding-right: 0px !important;
	}

	#level2-content.card.MobRemoveLeftPadding,
	.MobRemoveLeftPadding {
		padding-left: 0px !important;
	}

	.text-subsection-title {
		padding-left: 10px;
	}

	app-export-legacy-ref-tab .card {
		width: 100% !important;
	}

	.padding-u {
		margin-left: 0px !important;
		margin-right: 0px !important;
	}

	.list-container {
		min-height: 0px !important;
		overflow: hidden !important;
		padding-bottom: 0px !important;

		.fabColumn {
			position: relative;
			float: right;
			left: auto;
			right: auto;
			display: block;
			padding: 0px;

			.column {
				flex-direction: row !important;
			}
		}
	}

	.property-allocation-panels {
		padding-top: 20px;
	}

	.property-unallocated-panel {
		padding-top: 20px !important;
		padding-left: 0px !important;
	}

	app-property-details {
		margin-top: 40px !important;
	}

	.propertiesTableContainer {
		overflow: auto;
	}

	.propertiesTable {
		min-width: 800px;
		margin-bottom: 100px;
	}

	.app-chaining-properties {
		border-left: none !important;
		margin-bottom: 20px;

		.centerColumn {
			padding: 0px !important;
		}
	}

	app-chaining-values {
		padding-bottom: 100px !important;
		float: left;
		width: 100%;
	}

	.ag-theme-balham .ag-root {
		min-height: 500px;
	}

	.ag-root-wrapper-body.ag-layout-normal {
		height: auto !important;
	}

	app-extract-history {
		max-width: 100%;

		mat-table {
			min-width: 600px;
		}
	}

	.ag-root.ag-layout-normal {
		height: auto !important;
	}

	#footer {
		position: absolute;
	}

	.categorySelect {
		border-left: none !important;
	}

	.treeControls {
		position: relative !important;
	}

	app-exporter-blueprint-tree {
		border: none !important;
	}

	app-exporter-mapping-node {
		padding-left: 10px !important;
		padding-right: 10px !important;

		.mat-nav-list {
			padding-left: 0px !important;
			padding-right: 0px !important;
		}
	}

	.mobNoLRMargin {
		margin-left: 0px !important;
		margin-right: 0px !important;
	}

	app-swagger {
		mat-panel-title {
			font-size: 12px !important;
		}

		.mat-expansion-panel-content {
			form {
				display: block !important;
				width: 100%;

				* {
					display: block !important;
					float: left;
					width: 100%;
				}
			}

			mat-form-field {
				display: block !important;
				width: 100%;
			}
		}
	}

	textarea.stretchy {
		min-height: 500px;
	}

	.versionClientsTab {
		display: block !important;
		float: left !important;
		width: 100% !important;

		.mat-table {
			min-width: 1200px;
		}

		.list-container {
			display: block !important;
			overflow: auto !important;
			float: left !important;
			width: 100% !important;
		}
	}

	.mobHeightAuto {
		height: auto !important;
		overflow: hidden;
	}
}

@media screen and (max-width: 1000px) {
	app-product-view-config {
		.aHubConfigPage {
			.inner-container {
				flex-direction: column !important;

				app-list-tree {
					margin: 0px 0px 20px 0px !important;

					.mat-tree {
						max-width: 100% !important;
					}
				}
			}
		}

		.reset-button {
			top: auto !important;
			right: 50px;
			bottom: 90px;
		}
	}
}

@media screen and (max-height: 768px) {
	body,
	html {
		height: auto !important;
	}
}

@media screen and (max-height: 890px) {
	.initialScreenImage {
		width: 200px !important;
	}

	.main-selection-container {
		margin-top: 0px !important;
	}
}

@media screen and (max-width: 670px) {
	.top-right-widgets {
		top: 28px !important;
		right: 59px !important;
	}

	.translateIcon {
		top: 28px;
		right: 31px;
	}
}

@media screen and (max-width: 450px) {
	.noResults img {
		width: 150px;
	}

	app-client-quota,
	.quota {
		width: 100% !important;
		margin-left: 0px !important;
		margin-right: 0px !important;
	}
}

// @media screen and (max-width: 1595px) and (min-width: 1350px) {

//   app-welcome-home-nav-widget {
//     width: 100% !important;
//     margin-left: 0px !important;
//     margin-right: 0px !important;
//   }

//   .welcome-image-container {
//     display: none !important;
//   }

//   .card-4-inner {
//     padding: 20px !important;
//   }

// }

// aView
@media screen and (max-width: 1942px) and (min-width: 1360px),
	screen and (max-width: 920px) {
	.compareModeEnabled {
		.selection-reaction-count,
		.selection-menu-items {
			overflow: visible !important;
		}

		.reaction-toggle-group {
			position: absolute;
			z-index: 9000;
			left: 0px;
			display: none;
			bottom: -10px;
			transform: translateY(100%);
		}

		.mobile-compare-reaction-menu {
			display: block;
		}

		.compare-reaction-menu-small-screen {
			position: relative;
			height: 32px;
			overflow: visible;
			border-radius: 4px;
			background: #f2f5f9;
		}

		.reaction-menu-hover-mobile {
			position: relative;
			overflow: visible;
			cursor: pointer;

			&:hover {
				.reaction-toggle-group {
					display: flex;
					background: #fff;
					padding: 10px;
					box-shadow: 0px 5px 40px 0px #435d7b66 !important;
					border-radius: 4px;

					&:before {
						content: "";
						position: absolute;
						border: 10px solid transparent;
						border-bottom-color: #fff;
						display: block;
						top: -20px;
						left: 5px;
					}

					&:after {
						content: "";
						position: absolute;
						display: block;
						top: -20px;
						height: 20px;
						width: 100%;
						left: 0px;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1111px) {
	.selection-reaction-count,
	.selection-menu-items {
		overflow: visible !important;
	}

	.reaction-toggle-group {
		position: absolute;
		z-index: 9000;
		left: 0px;
		display: none;
		bottom: -10px;
		transform: translateY(100%);
	}

	.mobile-compare-reaction-menu {
		display: block;
	}

	.compare-reaction-menu-small-screen {
		position: relative;
		height: 32px;
		overflow: visible;
		border-radius: 4px;
		background: #f2f5f9;
	}

	.reaction-menu-hover-mobile {
		position: relative;
		overflow: visible;
		cursor: pointer;

		&:hover {
			.reaction-toggle-group {
				display: flex;
				background: #fff;
				padding: 10px;
				box-shadow: 0px 5px 40px 0px #435d7b66 !important;
				border-radius: 4px;

				&:before {
					content: "";
					position: absolute;
					border: 10px solid transparent;
					border-bottom-color: #fff;
					display: block;
					top: -20px;
					left: 5px;
				}

				&:after {
					content: "";
					position: absolute;
					display: block;
					top: -20px;
					height: 20px;
					width: 100%;
					left: 0px;
				}
			}
		}
	}
}

@media screen and (max-width: 1360px) {
	.main-form-button {
		display: flex !important;
	}

	.mobile-discussion {
		display: flex !important;
	}

	.mobile-aview-hide {
		display: none !important;
	}

	.desktop-discussion {
		display: none !important;
	}

	.top-bar {
		height: 80px !important;

		.aview-main-form-container,
		.pt5 {
			padding-top: 0px;
		}
	}

	.compareModeEnabled {
		flex-direction: column;
	}

	// Selections in compare mode
	.aview-main-form {
		position: absolute;
		flex-direction: column;
		background: #fff;
		padding: 20px;
		z-index: 2;
		border-radius: 8px;
		left: 25px;
		bottom: -11px;
		transform: translateY(calc(100% - 20px));
		display: none;
		align-items: start !important;
		box-shadow: 0px 5px 20px 0px #435d7b26 !important;

		app-select-with-search-grouped,
		.mat-form-field {
			margin-left: 0px !important;
		}

		app-select-with-search-grouped {
			width: 100% !important;
		}

		&:before {
			content: "";
			display: block;
			position: absolute;
			top: -17px;
			left: 3px;
			border: 10px solid transparent;
			border-bottom-color: #fff;
			z-index: 90;
		}

		&:after {
			content: "";
			display: block;
			position: absolute;
			width: 100%;
			height: 20px;
			top: 0px;
			transform: translateY(-100%);
			left: 0px;
			z-index: 90;
		}
	}
}
