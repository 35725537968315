// Colours
$IconColor: #2b2b2b;
$tGrey1: #363f67;
$tGrey2: #8687a7;
$bgGrey1: #e8edf4;
$tBlue1: #6074e2;

$shadow: #dbe3ea;

$purple: #b18bbf;
$blue: #6074e2;
$turq: #40c2cc;

// Positioning
$center: translate(-50%, -50%);

// Nice colours
$borderColor: #e5ebef;
$darkText: #22253a;
