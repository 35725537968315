:not(
		.chip-bar,
		.menu-scroll,
		.slim-scroll,
		.slim-scroll-2,
		.side-tabs
	)::-webkit-scrollbar {
	width: 18px;
	height: 10px;
}

:not(
		.chip-bar,
		.menu-scroll,
		.slim-scroll,
		.slim-scroll-2,
		.side-tabs
	)::-webkit-scrollbar-track {
	box-shadow: inset 0 0 10px 10px rgb(179 188 199 / 34%);
	border-left: solid 8px transparent;
}

:not(
		.chip-bar,
		.menu-scroll,
		.slim-scroll,
		.slim-scroll-2,
		.side-tabs
	)::-webkit-scrollbar-thumb {
	box-shadow: inset 0 0 10px 10px #5465bdcc;
	border-left: solid 8px transparent;
}

.DashboardScrollBar::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

.DashboardScrollBar::-webkit-scrollbar-track-piece {
	background-color: rgb(211, 211, 211);
}

.DashboardScrollBar::-webkit-scrollbar-thumb {
	background-color: rgba(255, 255, 255, 0.6);
	border-radius: 0px;
}

#MainMenu::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

.DashboardScrollBar::-webkit-scrollbar-button:start:decrement,
.DashboardScrollBar::-webkit-scrollbar-button:end:increment {
	display: block;
	height: 0;
	background-color: transparent;
}

#MainMenu::-webkit-scrollbar-track-piece {
	background-color: rgba(255, 255, 255, 0.2);
}

#MainMenu::-webkit-scrollbar-thumb {
	background-color: rgba(255, 255, 255, 0.3);
	border-radius: 0px;
}

#MainMenu::-webkit-scrollbar-button:start:decrement,
#MainMenu::-webkit-scrollbar-button:end:increment {
	display: block;
	height: 0;
	background-color: transparent;
}

.menu-scroll,
.chip-bar {
	margin: 0px 15px;

	/* Works on Chrome/Edge/Safari */
	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}

	&::-webkit-scrollbar-track {
		background: #e8edf3;
		border-radius: 5px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #c8cdd5;
		border-radius: 20px;
		border: 1px solid #e8edf3;
	}
}

.slim-scroll {
	/* Works on Chrome/Edge/Safari */
	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}

	&::-webkit-scrollbar-track {
		background: #e8edf3;
		border-radius: 5px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #c8cdd5;
		border-radius: 20px;
		border: 1px solid #e8edf3;
	}
}

.slim-scroll-2 {
	/* Works on Chrome/Edge/Safari */
	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}

	&::-webkit-scrollbar-track {
		background: rgba(255, 255, 255, 0.4);
		border-radius: 5px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #c8cdd5;
		border-radius: 20px;
		border: 1px solid #e8edf3;
	}
}
